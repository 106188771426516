<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ label }}</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="">
          <v-alert
            dense
            border="left"
            type="warning"
            dismissible
            v-if="exampleFile.need_to_show_message"
          >
            <span v-html="exampleFile.message"></span>
          </v-alert>
        </div>
        <v-card elevation="0" class="mb-4">
          <form id="createForm" class="pa-3 text-center">
            <v-file-input
              v-model="formData.file"
              label="File"
              outlined
              dense
              :error-messages="fileErrors"
              @input="$v.formData.file.$touch()"
              @blur="$v.formData.file.$touch()"
            ></v-file-input>
            <!-- add example file -->
            <v-btn class="primary--text" outlined text :href="exampleFile.url">
              Download Template
            </v-btn>
            <v-main class="text-center mt-4">
              <button
                type="button"
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitEditForm"
              >
                Import
              </button>
            </v-main>
          </form>
        </v-card>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ImportData",
  mixins: [validationMixin],
  validations: {
    formData: {
      file: { required },
    },
  },
  props: ["type", "label", "setData", "isCountry", "countryId"],
  data: () => ({
    dialog: false,

    formData: {
      file: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      let data = new FormData();
      data.append("type", this.type);
      data.append("file", this.formData.file, this.formData.file.name);
      if (this.isCountry == 0 && this.countryId != null) {
        data.append("country_id", this.countryId);
      }
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = this.convertToFormData();
      ApiService.post("/cod/finance_services/parse_rates", data)
        .then((res) => {
          Swal.fire({
            title: "Imported",
            text: `Data is imported!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.setData(res.data.data);
          this.toggleModal();
          this.resetEditForm();
        })
        .catch((res) => {
          Swal.fire({
            title: "Error",
            text: `${res.data.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData.file = null;
    },
  },
  computed: {
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    exampleFile() {
      let file = null;
      if (this.type == "rate") {
        file = this.$store.getters.getSERVICESExampleFiles.rates_sample_file;
      } else {
        file =
          this.$store.getters.getSERVICESExampleFiles.increments_sample_file;
      }
      return file;
    },
  },
};
</script>
